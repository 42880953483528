import { Navigate, Route, Routes } from 'react-router-dom';
import './styles/main.scss';
import Main from './views/Main';
import Incidents from './views/Surveys/Incidents';
import SurveyDone from './views/Surveys/SurveyDone';
import SendBudget from './views/SendBudget';
import CreateIncidents from './views/CrmViews/CreateIncidents';
import { ToastContainer } from 'react-toastify';
import CrmActionDone from './views/CrmViews/CrmActionDone';
import ProviderShipmentInfo from './views/CrmViews/ProviderShipmentInfo';
import PartProblem from './views/CrmViews/PartProblem';
import OfflineRequestResponse from './views/CrmViews/OfflineRequestResponse';
import ReconstruidosRequestResponse from './views/CrmViews/ReconstruidoRequestResponse';
import IntegracionesRequestResponse from './views/CrmViews/IntegracionesRequestResponse';
import PartConfirm from './views/CrmViews/PartConfirm';

function App () {
    return (
        <>
            <Routes>
                <Route path='*' exact={true} element={<Main />}/>
                <Route path='*' exact={true} element={<Navigate to="/" />}/>
                <Route path='/incidencias/:incidenciaId/encuesta' element={<Incidents />} />
                <Route path='/incidencias-vo/:incidenciaId/encuesta' element={<Incidents comesFrom='vo' />} />
                <Route path='/encuesta/finalizada' element={<SurveyDone />} />
                <Route path='/presupuesto/:checkoutId' element={<SendBudget />} />
                <Route path='/gracias' element={<CrmActionDone />} />
                <Route path='/solicitudes/:ventaId/incidencia' element={<CreateIncidents />} />
                <Route path='/solicitudes-vo/:ventaId/incidencia' element={<CreateIncidents comesFrom='vo' />} />

                <Route path='/integracion/:ventaId/problema/:proveedorId' element={<PartProblem />} />
                <Route path='/integracion-vo/:ventaId/problema/:proveedorId' element={<PartProblem comesFrom='vo' />} />
                <Route path='/integracion/:ventaId/confirmacion/:proveedorId' element={<PartConfirm />} />
                <Route path='/integracion-vo/:ventaId/confirmacion/:proveedorId' element={<PartConfirm comesFrom='vo' />} />

                <Route path='/integracion/respuesta_solicitud' element={<IntegracionesRequestResponse />} />
                <Route path='/venta-online/integracion/respuesta_solicitud' element={<IntegracionesRequestResponse comesFrom='vo' />} />

                <Route path='/proveedores/:proveedorId/:ventaId/informacion' element={<ProviderShipmentInfo />} />
                <Route path='/desguaces_offline/respuesta_solicitud' element={<OfflineRequestResponse />} />
                <Route path='/reconstruidos/respuesta_solicitud' element={<ReconstruidosRequestResponse />} />
            </Routes>

            <ToastContainer
                containerId="dashboard"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </>
    );
}

export default App;
