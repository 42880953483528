import { useEffect, useState } from 'react';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../shared/helpers/functionalities';
import { toast } from 'react-toastify';
import { savePartConfirm, saveVoPartConfirm } from '../shared/services/integraciones';

export default function PartConfirm ({ comesFrom }) {
    const { t } = useTranslation();
    const { ventaId, proveedorId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getInfo = async () => {
            let response;

            if (comesFrom) {
                response = await saveVoPartConfirm({
                    venta_id: ventaId,
                    proveedor_id: proveedorId
                }).catch(function (error) {
                    toast.error(setErrorMessage(error));
                });
            } else {
                response = await savePartConfirm({
                    venta_id: ventaId,
                    proveedor_id: proveedorId
                }).catch(function (error) {
                    toast.error(setErrorMessage(error));
                });
            }

            if (response && response.success) {
                setLoading(false);
                navigate('/gracias', { state: { from: 'providers' } });
            }
        };

        if (proveedorId && ventaId) {
            getInfo();
        } else toast.error('No hay venta!');
    }, []);

    return (
        <div className='w-100 h-100 d-flex flex-column justify-content-between'>
            <Header />

            { (loading)
                ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                            <span className="my-5">{ t('common.loading') }</span>
                        </div>
                    </div>
                )
                : (
                    <div className='w-100 container d-flex align-items-center justify-content-center mt-5'>
                    </div>
                )}

            <Footer />
        </div>
    );
}
