import { memo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { SidebarData } from './SidebarData';
import './Navbar.scss';
import { useNavigate } from 'react-router-dom';
import logo from '../../../../../images/recomotor_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGlobe, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Navbar = ({
    onOpenAltaTaller
}) => {
    const { t } = useTranslation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const btnRef = useRef();

    const pathname = window.location.pathname === '/' ? 'inicio' : window.location.pathname.replace('/', '');
    const navigate = useNavigate();

    const handleClickMenuItem = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    // eslint-disable-next-line react/display-name
    const NavItem = memo(({ children }) => {
        return (
            <>
                {children}
            </>
        );
    }, isEqual);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleCloseMenuMob = () => {
        btnRef.current.click();
    };

    return (
        <nav className="navbar navbar-expand-md bg-body-tertiary">
            <div className="container d-flex align-items-stretch justify-content-between">
                <div className="container d-flex align-items-center justify-content-between">
                    <span className="navbar-brand" onClick={() => {
                        handleClickMenuItem('/');
                        isNavOpen && handleCloseMenuMob();
                    }
                    } style={{ cursor: 'pointer' }}>
                        <NavItem>
                            <img src={logo} className="brand logo-nav" alt="Recomotor" />
                        </NavItem>
                    </span>

                    <button ref={btnRef} onClick={() => setIsNavOpen(!isNavOpen)} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faBars} size="1x" className={ isNavOpen ? 'action navbar-icon' : 'action navbar-icon-active'} color="#215732" />
                        <FontAwesomeIcon icon={faTimes} size="1x" className={ isNavOpen ? 'action navbar-icon-active navbar-icon-close' : 'action navbar-icon'} color="#215732" />
                    </button>
                </div>

                <div className="collapse navbar-collapse align-items-center" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        { SidebarData.map((item, index) => {
                            return (
                                <NavItem key={index}>
                                    <li
                                        onClick={() => {
                                            handleClickMenuItem(item.path);
                                            isNavOpen && handleCloseMenuMob();
                                        }}
                                        className={`${item.cName}${item.path === '/' + pathname ? '-active' : ''}`}
                                        style={{ cursor: 'pointer' }}>
                                        <span>{t(`navbar.${item.path}`)}</span>
                                    </li>
                                </NavItem>
                            );
                        })}
                    </ul>

                    <div className={`change-language ${isNavOpen ? 'change-language__show' : 'change-language__hide'}`}>
                        <FontAwesomeIcon className="change-language__icon" icon={faGlobe} />
                        <select className="change-language__select" defaultValue={i18n.language} name="language" onChange={(e) => changeLanguage(e.target.value)}>
                            <option value='es-ES'>ES</option>
                            <option value='en-EN'>EN</option>
                            <option value='fr-FR'>FR</option>
                            <option value='pt-PT'>PT</option>
                        </select>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => onOpenAltaTaller()}>
                            {t('navbar.button')}
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

/*

<button type="button" className="btn btn-sm btn-outline-primary" onClick={() => handleClickMenuItem('alta-taller')}>
    Acceso talleres
</button>

*/

export default memo(Navbar);
