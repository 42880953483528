import { useTranslation } from 'react-i18next';
import './incidents.scss';
import Stars from '../shared/components/Stars';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { submitSurveyIncidents, submitSurveyIncidentsVo } from '../shared/services/surveys';
import logo from '../../../images/recomotor_logo.png';
import Spinner from '../../Main/shared/components/Spinner';
import { toast } from 'react-toastify';

const Incidents = ({ comesFrom }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { incidenciaId } = useParams();
    const [surveyResults, setSurveyResults] = useState({
        rapidez: '',
        solucion: '',
        experiencia: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleStars = (e) => {
        setSurveyResults({
            ...surveyResults, [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        let res;

        comesFrom === 'crm'
            ? res = await submitSurveyIncidents({ surveyResults, incidencia_id: incidenciaId })
            : res = await submitSurveyIncidentsVo({ surveyResults, incidencia_id: incidenciaId });

        if (res?.success) {
            return navigate('/encuesta/finalizada');
        }

        setIsLoading(false);
        return toast.error(t('errors.1'));
    };

    return (
        <>
            <div className='incidents'>
                <div className='incidents__header'>
                    <img src={logo} className='incidents__header--img' alt="Recomotor" />
                    <h1>{t('surveys.title')}</h1>
                </div>
                <div className='incidents__container'>
                    <div className='incidents__container--each'>
                        <p>{t('surveys.incidents.questions.1')}</p>
                        <Stars name='rapidez' handleStars={handleStars} />
                    </div>
                    <div className='incidents__container--each'>
                        <p>{t('surveys.incidents.questions.2')}</p>
                        <Stars name="solucion" handleStars={handleStars} />
                    </div>
                    <div className='incidents__container--each'>
                        <p>{t('surveys.incidents.questions.3')}</p>
                        <Stars name="experiencia" handleStars={handleStars} />
                    </div>
                </div>
                <button className='incidents__btn' onClick={handleSubmit}>{isLoading ? <Spinner /> : t('surveys.submit')}</button>
            </div>
        </>
    );
};

export default Incidents;
