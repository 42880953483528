import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import tick from '../../../../../../images/tick.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../shared/components/Spinner';
import GooglePlacesAutocomplete from '../GooglePlacesAutocomplete';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { handleValidPhone } from '../../../../../../utils';
export default function VendoCocheForm ({
    onSubmitCoche
}) {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const conctactoSchema = yup.object({
        email: yup.string().required('El email es obligatorio.').email('Formato de email incorrecto.'),
        telefono: yup.string().required('El teléfono es obligatorio.').min(9, 'El teléfono es obligatorio.'),
        marca_id: yup.number().integer().moreThan(0, 'La marca es obligatoria.').required('La marca es obligatoria.'),
        modelo_id: yup.number().integer().moreThan(0, 'El modelo es obligatorio.').required('El modelo es obligatorio.'),
        anyo: yup.string().required('El año es obligatorio.').matches(/^\d{4}$/, 'El año requiere 4 cifras'),
        matricula: yup.string().required('La matrícula es obligatoria.').min(4, 'La matrícula debe contener como mínimo 4 caracteres'),
        ubicacion: yup.string().required('ubicacion').min(10, 'selectoption'),
        comentario: yup.string().required('El comentario es obligatorio.')
    }).required();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        getValues,
        trigger,
        clearErrors
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(conctactoSchema),
        defaultValues: {
            email: '',
            telefono: '',
            marca_id: 0,
            modelo_id: 0,
            anyo: null,
            ubicacion: '',
            matricula: '',
            fotos: '',
            comentario: ''
        }
    });

    const marcas = JSON.parse(localStorage.getItem('marcas'));
    const modelos = JSON.parse(localStorage.getItem('modelos'));

    const [step, setStep] = useState(1);
    const [condiciones, setCondiciones] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [filteredModels, setFilterModels] = useState(modelos);
    const [showMsgFile, setShowMsgFile] = useState(false);
    const [isFileSizeOk, setIsFileSizeOk] = useState(true);
    const [nameFiles, setNameFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeCondiciones = (e) => {
        setCondiciones(e.target.checked);
    };

    const handleChangeBrand = (e) => {
        const marcaId = parseInt(e.target.value);
        setFilterModels(modelos.filter((model) => model.marca_id === marcaId));
        setValue('marca_id', marcaId);
        clearErrors('marca_id');
    };

    const handleChangeModel = (e) => {
        setValue('modelo_id', parseInt(e.target.value));
        clearErrors('modelo_id');
    };

    const forceToValidateErrors = () => {
        if (step === 1) trigger(['email', 'marca_id', 'modelo_id', 'anyo']);
        if (step === 2) trigger(['ubicacion', 'matricula', 'file']);
        if (step === 3) trigger('comentario');
    };

    const nextStep = () => {
        forceToValidateErrors();
        const values = getValues();

        if (step === 1 && !errors.email && !errors.anyo && values.anyo && values.marca_id > 0 && values.modelo_id > 0) {
            clearErrors();
            setStep(step + 1);
        } else if (step === 2 && !errors.ubicacion && !errors.matricula) {
            if (values.fotos === '' || !isFileSizeOk) {
                setShowMsgFile(true);
            } else {
                setShowMsgFile(false);
                clearErrors();
                setStep(step + 1);
            }
        } else {
            !errors.comentario && trigger();
        }
    };

    const prevStep = () => {
        step === 2 && setShowMsgFile(false);
        setStep(step - 1);
    };

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));
        setIsLoading(true);
        const response = await onSubmitCoche({ ...data, captchaValue });
        setIsSubmit(response);
        setNameFiles([]);
        setIsLoading(false);
    };

    const handleNewTasacion = () => {
        reset();
        setStep(1);
        setIsSubmit(false);
    };

    const handleSizeFiles = (e) => {
        const arrNames = [];
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].size > 4000000) {
                setIsFileSizeOk(false);
            } else {
                arrNames.push(e.target.files[i].name);
                setIsFileSizeOk(true);
                setShowMsgFile(false);
            }
        }
        setNameFiles(arrNames);
    };

    // validaciones input anyo
    const [anyo, setAnyo] = useState('');

    const handelAnyoChange = (e) => {
        const inputValue = e.target.value;
        // Eliminar caracteres no numéricos y limitar a 4 dígitos
        let newValue = inputValue.replace(/\D/g, '').slice(0, 4);
        // Limitar el max al año actual
        newValue = Math.min(newValue, new Date().getFullYear());
        setAnyo(newValue);
    };

    const handleChangeDireccion = (location) => {
        if (location.length >= 10 && errors.ubicacion) clearErrors('ubicacion');
        setValue('ubicacion', location);
    };

    return (
        <div className="container w-100 d-flex flex-column align-items-center justify-content-center form-container">
            <div className="form-wrapper col-12 col-md-9 col-lg-6 d-flex flex-column align-items-center">
                { (!isSubmit)
                    ? <>
                        <h5 className="text-uppercase text-center fw-bold">{t('vende.form.title')}</h5>
                        <p className="mt-2 mb-5">{t('vende.form.subtitle.1')} <br /> {t('vende.form.subtitle.2')}</p>

                        <form className="form w-100 d-flex flex-column align-items-center justify-content-center" onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-12 col-md-9 d-flex flex-column">
                                <div className="w-100 d-flex align-items-center steps mb-5">
                                    <span className={'step ' + (step === 1 ? 'active' : '')}>
                                        <h5>1</h5>
                                    </span>
                                    <span className="divider"></span>
                                    <span className={'step ' + (step === 2 ? 'active' : '')}>
                                        <h5>2</h5>
                                    </span>
                                    <span className="divider"></span>
                                    <span className={'step ' + (step === 3 ? 'active' : '')}>
                                        <h5>3</h5>
                                    </span>
                                </div>

                                { (step === 1) &&
                                    <div className="w-100">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                {...register('email', { required: true, minLength: 4 })}
                                                placeholder={t('vende.form.placeholders.email')}
                                                defaultValue={''}
                                                maxLength={50} />

                                            <div className="form-invalid">
                                                {(errors.email) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t(`vende.form.errors.${errors.email.message}`)}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="telefono"
                                                className="form-control"
                                                {...register('telefono', { required: true, minLength: 9 })}
                                                placeholder={t('vende.form.placeholders.telefono')}
                                                defaultValue={''}
                                                onKeyDown={handleValidPhone} />

                                            <div className="form-invalid">
                                                {(errors.telefono) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t(`vende.form.errors.${errors.telefono.message}`)}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            {marcas && marcas.length > 0 && (
                                                <select
                                                    {...register('marca_id')}
                                                    defaultValue={0}
                                                    onChange={handleChangeBrand}
                                                >
                                                    <option value="0" disabled>{t('vende.form.placeholders.marca')}</option>
                                                    {marcas.map(marca => (
                                                        <option key={'marca-' + marca.id} value={marca.id}>{marca.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}

                                            <div className="form-invalid">
                                                {(errors.marca_id) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t('vende.form.errors.marca')}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            {filteredModels && filteredModels.length > 0 && (
                                                <select
                                                    {...register('modelo_id')}
                                                    defaultValue={0}
                                                    onChange={handleChangeModel}
                                                >
                                                    <option value="0" disabled>{t('vende.form.placeholders.modelo')}</option>
                                                    {filteredModels.map(modelo => (
                                                        <option key={'modelo-' + modelo.id} value={modelo.id}>
                                                            {modelo.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}

                                            <div className="form-invalid">
                                                {(errors.modelo_id) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t('vende.form.errors.modelo')}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder={t('vende.form.placeholders.año')}
                                                {...register('anyo', {
                                                    required: true,
                                                    pattern: /^\d{4}$/
                                                })}
                                                value={anyo === 0 ? '' : anyo}
                                                onChange={handelAnyoChange} />

                                            <div className="form-invalid">
                                                {(errors.anyo) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t(`vende.form.errors.${errors.anyo.message}`)}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                { (step === 2) &&
                                    <div className="w-100">
                                        <div className="form-group">
                                            <GooglePlacesAutocomplete
                                                onSelectResult={handleChangeDireccion}
                                                country={'ES'}
                                                disabled={false}
                                            />

                                            <div className="form-invalid">
                                                {(errors.ubicacion) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t(`vende.form.errors.${errors.ubicacion.message}`)}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('vende.form.placeholders.matricula')}
                                                {...register('matricula', { required: true, minLength: 4 })}
                                                defaultValue={''}
                                                minLength={4} />

                                            <div className="form-invalid">
                                                {(errors.matricula) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t(`vende.form.errors.${errors.matricula.message}`)}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="vende-coche-fotos">
                                                {t('vende.form.placeholders.fotos')}
                                                <label>
                                                    {t('vende.form.placeholders.file')}
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        {...register('fotos', { required: true })}
                                                        defaultValue={''}
                                                        multiple
                                                        onInput={handleSizeFiles}
                                                    />
                                                </label>
                                            </div>
                                            {nameFiles.length > 0 &&
                                             <div className='vende-coche-attachments'>
                                                 <p className='vende-coche-attachments__title'>{t('vende.form.placeholders.adjuntos')}</p>
                                                 <p className='vende-coche-attachments__archivos'>{nameFiles.map(name => <span key={name}><FontAwesomeIcon icon={faImage} /> {name}</span>)}</p>
                                             </div>}
                                            <div className="form-invalid">
                                                {(showMsgFile) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {isFileSizeOk ? `${t('vende.form.errors.fotos')}` : `${t('vende.form.errors.fotosSize')}`}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                { (step === 3) &&
                                    <div className="w-100 pb-3">
                                        <div className="form-group">
                                            <textarea
                                                type="text"
                                                placeholder={t('vende.form.placeholders.comentario')}
                                                className="form-control"
                                                rows={5}
                                                {...register('comentario', { required: true })}
                                                defaultValue={''}></textarea>

                                            <div className="form-invalid">
                                                {(errors.comentario) &&
                                                    <div className="w-100 mt-2">
                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                                        {t('vende.form.errors.comentario')}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className='w-100 d-flex align-items-center'>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    name="condiciones"
                                                    id="condiciones"
                                                    className='form-check-input'
                                                    onChange={handleChangeCondiciones}
                                                    defaultChecked={false} />
                                                <small className="form-check-label" htmlFor="condiciones">
                                                    {t('vende.form.checkbox.1')}{' '}
                                                    <Link to={'/politica-privacidad'} title="Política de privacidad" className="fw-bold">
                                                        {t('vende.form.checkbox.2')}
                                                    </Link>{' '}
                                                    {t('vende.form.checkbox.3')}{' '}
                                                    <Link to={'/condiciones-generales'} title="Condiciones generales" className="fw-bold">
                                                        {t('vende.form.checkbox.4')}
                                                    </Link>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {step === 3 && <div className='w-100 d-flex align-items-center justify-content-center pb-3'>
                                    <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                </div>}
                                <div className="w-100 d-flex align-items-center">
                                    { (step === 2 || step === 3) &&
                                        <button type="button" className="btn btn-link" onClick={() => {
                                            prevStep();
                                            condiciones && setCondiciones(false);
                                        }}>{t('vende.form.atras')}</button>
                                    }

                                    { (step === 1 || step === 2) &&
                                        <button type="button" className="btn btn-primary ms-auto" onClick={() => nextStep()}>{t('vende.form.next')}</button>
                                    }

                                    { (step === 3) &&
                                        <button
                                            type="submit"
                                            className="btn btn-primary ms-auto vende-coche-submit-btn"
                                            disabled={condiciones === false}
                                        >{isLoading ? <Spinner/> : t('vende.form.button')}</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </>
                    : <div className="w-100 d-flex flex-column align-items-center justify-content-center step-wrapper">
                        <span className="circle">
                            <img src={tick} alt="Solicitud hecha" />
                        </span>
                        <h5 className="text-uppercase fw-bold my-5 text-center">{t('vende.form.text-ok')}</h5>
                        <button type="button" className="btn btn-primary" onClick={() => handleNewTasacion()}>
                            {t('vende.form.text-nueva')}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}
