import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';

export default function CrmActionDone () {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { from } = state;

    useEffect(() => {
        setTimeout(() => navigate('/'), 5000);
    }, []);

    return (
        <div className="w-100 h-100 d-flex flex-column justify-content-between">
            <Header />

            <div className="container w-100 my-5">
                <div className="w-100 my-5 d-flex flex-column align-items-center justify-content-center py-5">
                    <div className="d-flex align-items-center justify-content-center text-success my-5">
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" color="#215732" />
                        <p className="mx-3">{ t('crm-views.gracias.text') }</p>
                    </div>

                    { (from && from === 'incident') &&
                        <p className="text-center mt-3">
                            { t('crm-views.gracias.incidence-text') } <br />
                            { t('crm-views.gracias.incidence-text2') }
                        </p>
                    }
                </div>
            </div>

            <Footer />
        </div>
    );
}
